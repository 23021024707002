import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BGSlideshow from "../components/bg_slideshow"
import Accordion from "../components/accordion"

import styles from "./index.module.scss"


import SL1 from "../images/12132616_897522180295513_2403437913656357964_o.jpg"
import SL2 from "../images/467665_320827107965026_945010742_o.jpg"
import SL3 from "../images/20160907_180128.jpg"
import SL4 from "../images/20170913_134523.jpg"
import SL5 from "../images/20170914_173932.jpg"
import SL6 from "../images/65569479_2309234605790923_8219389329374445568_o.jpg"
import SL7 from "../images/61520003_161076254931975_1245127992810051582_n.jpg"

import Trailer from "../images/trailer.svg"
import Van from "../images/van.svg"
import Worker from "../images/worker.svg"
import Warehouse from "../images/warehouse.svg"
import Users from "../images/users.svg"

import STR from "../images/schwertransporte.jpg"
import SBGL from "../images/SBGL.jpg"
import BBGL from "../images/BBGL.jpg"
import Schulung from "../images/Schulung.jpg"
import Begleit2 from "../images/Begleit2.jpg"
import Lager from "../images/Lager.jpg"

export default () => (
    <Layout allwaysTop={false} active="/leistungen">
        <SEO title="Leistungen" />
        <BGSlideshow style={{zIndex:-1}} entries={
            [
                {bgImage: SL1, animationDelay: 0, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL2, animationDelay: 6, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL3, animationDelay: 12, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL4, animationDelay: 18, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL5, animationDelay: 24, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL6, animationDelay: 30, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL7, animationDelay: 36, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
            ]} />

        <article style={{background:'white'}}>
        <div className={styles.content_div} style={{marginTop: '100vh'}}>
            <div>            
                <h2>Unsere Leistungen</h2>

                <p>Service, dies bedeutet für Sie: Alles aus einer Hand.</p>
                <p>Service beginnt bei uns, wenn das eigentliche Leistungspaket längst geschnürt ist.</p>
                <p>Baustellenbesichtigungen, Streckenerkundungen, nebst der Ausfertigung von Streckenprotokollen, Krangestellungen, Einholung aller behördlicher Transportgenehmigungen, Einrichtung von Halte- und Parkverbotszonen, Verlegung von Fahrblechen, De- und Montage von Verkehrsschildern - kurzum die gesamte logistische Vorbereitung, Versicherungsfragen sowie die Transportbegleitung mit eigenen BF2- und BF3-Fahrzeugen gehören bei uns zum täglichen Geschäft.</p>
                <p>Darüber hinaus betreiben wir eine eigene Flotte von BF 4 – Fahrzeugen, die in der Hansestadt Rostock bereits erfolgreich für Polizei ersetzende Begleitungen von Großraum- und Schwertransporten als Verwaltungshelfer eingesetzt werden.</p>

            </div>
            <Accordion image={Trailer} header="Schwertransport">
            <img src={STR} style={{width:'100%', padding: '0'}} alt="Schwertransport"/>
                <p>Ein „normaler“ LKW reicht für Ihre Güter nicht aus? Egal ob Überlänge, Überbreite oder ein hohes Gewicht - alles kein Problem - denn wir lösen auch schwierige Aufgaben mit Leichtigkeit</p>
                <p style={{clear: 'both'}}>Wir unterstützen Sie bei:</p>
                <ul>
                    <li>organisatorischen Aufgaben z.B. wie das Einholen der erforderlichen Transportgenehmigungen,</li>
                    <li>Überwachung und Kontrolle der zu erfüllenden Auflagen und Bedingungen,</li>
                    <li>Streckenerkundung und -prüfung im Vorfeld,</li>
                    <li>Einholen statischer Brückenberechnungen,</li>
                    <li>Verkehrslenkende Maßnahmen aller Art in Abstimmung mit den Behörden,</li>
                    <li>der sicheren &amp; zügigen Abwicklung Ihres Transports mit unseren eigenen GPS – gestützten Fahrzeugen.</li>
                </ul>
            </Accordion>
            <Accordion image={Van} header="Schwertransportbegleitungen">
                <img src={SBGL} style={{width: '100%'}} alt="Schwertransportbegleitungen"/>
                <p>Wir bieten Ihnen BF2 oder BF3 Begleitfahrzeuge mit geschulten Fahrern für eine professionelle Begleitung Ihres Schwertransportes – sicher und zuverlässig. Zusätzlich haben Sie auch die Möglichkeit unseren Beifahrerservice und unsere Beladungs- und Entladungshilfe zu buchen. Sprechen Sie uns an.</p>
                <p>Unser Fahrpersonal ist dabei entsprechend geschult und unsere Fahrzeuge entsprechen allen gesetzlichen Anforderungen.</p>
            <div style={{clear: 'both'}}></div>
            </Accordion>
            <Accordion image={Van} header="Verwaltungshelfer">
                <img src={Begleit2} style={{width: '100%'}} alt="Verwaltungshelfer"/>
                <p>Nach den Randnummern 131 und 132 der allgemeinen Verwaltungsvorschrift zu den § 29 Absatz 3 der Straßenverkehrs – Ordnung ist zur Durchführung mancher Großraum- und Schwertransporte ganz oder teilweise eine polizeiliche Begleitung erforderlich.</p>
                <p>Mit unseren BF 4 – Fahrzeugen können wir als Verwaltungshelfer im Vorhinein planbare und regelbare Streckenabschnitte mit Standardsituationen und –fällen bei der Begleitung von Großraum- und Schwertransporten mit einer entsprechenden Planung ohne Polizeibegleitung durchführen.</p>
                <p>Dabei stellen wir nicht nur unser ausgebildetes Personal und unsere Fahrzeugtechnik zur Verfügung, wir übernehmen für Sie auch die Erstellung der erforderlichen Regelpläne, die als Grundlage der notwendigen verkehrsrechtlichen Anordnung gem. § 45 Absatz 1 – 3 StVO dient. </p>
            </Accordion>
            <Accordion image={Worker} header="Baustellenberäumungen">
            <img src={BBGL} style={{width: '100%'}} alt="Baustellenberäumungen"/>
                <p>Wir unterstützen Sie mit unserem Team bei der Beräumung von Baustellen, demontieren und montieren Verkehrszeichen, beräumen Hindernisse und sorgen somit für einen reibungslosen Ablauf Ihres Großraum- oder Schwertransportes. Freie Fahrt für Ihre Fahrzeuge.</p>
                <p>Unsere Mitarbeiterinnen und Mitarbeiter sind nach MVAS 99 ("Merkblatt über Rahmenbedingungen für erforderliche Fachkenntnisse zur Verkehrssicherung von Arbeitsstellen an Straßen")  geschult und dürfen auf allen Autobahnen, Bundes-, Land- und Kreisstraßen sowie innerstätisch arbeiten.</p>
                <p>Unsere Werkstattwagen sind dabei mit Allem notwendigen Werkzeugen – vom Schilderschlüssel über Schweißgeräte bis hin zum Stromaggregat ausgerüstet.</p>
                <p>Wir helfen gerne!</p>
            </Accordion>
            <Accordion image={Warehouse} header="Lagerung">
            <img src={Lager} style={{width: '100%'}} alt="Lagerung" />
                <p>Parallel dazu betreiben wir an unserem Standort in Papendorf ein 1.800 m² großes, videoüberwachtes Hallen- sowie 5.000 m² großes Freilager mit direkter Autobahnanbindung, zentrumsnah an Rostock und eigener Gabelstaplertechnik.</p>
                <p>So arbeiten wir erfolgreich unter anderem seit vielen Jahren mit Kunden wie der Siemens Gruppe oder den Caterpillar Werken.</p>
                <p>Ob kurz- oder langfristige Lagerung, sprechen Sie uns einfach an!</p>
            </Accordion>
            <Accordion image={Users} header="Schulungen">
            <img src={Schulung} style={{width:'100%'}} alt="Schulungen" />
            <p>Über die SVS Rostock bieten wir seit zwei Jahren Schulungen und Beratungen rund um das Thema Großraum- und Schwertransporte an.</p>
            <p>Dafür kommen wir zu Ihnen ins Haus, können aber auch unseren firmeneigenen Schulungs- und Konferenzraum anbieten.</p>
            <p>Wir schulen Ihre Mitarbeiterinnen und Mitarbeiter im Umgang mit</p>
                <ul>
                    <li>Genehmigungsverfahren in „VEMAGS“,</li> 
                    <li>Ausnahmegenehmigungen nach § 70 StVZO,</li>
                    <li>Ausnahmegenehmigung und Erlaubnisse nach §§ 46 und 29,</li>
                    <li>das Einsetzen von Begleitpersonal auf BF 3 – Fahrzeugen (firmeneigen und firmenfremd)</li>
                    <li>den Kenntlichmachungsrichtlinien für überlange und überbreite Straßenfahrzeugen</li>
                </ul>
             <p>oder übernehmen für Sie die vom Verordnungsgeber vorgeschriebene vierteljährliche Unterweisung nach § 70 gem. StVZO Ihres Fahrpersonales.</p>
            <p>Da wir seit mittlerweile fast 30 Jahren in der Branche tätig sind, können wir nichts anderes und geben unser Wissen gerne weiter. Profitieren Sie von unserem Know – how!</p>
            </Accordion>
        </div>
        <div  style={{background: '#efefef'}}>

        <div className={styles.content_div}>
            <div>
                <p>Bundesweit, bis ins benachtbarte Ausland betreuen wir Sie und sind dabei öfter in Ihrer Nähe als Sie denken. Unsere Unternehmensstruktur erlaubt es, uns um jeden Transport, um jede Transportbegleitung individuell zu kümmern und das 24 Stunden - rund um die Uhr.</p>
                <p>Nehmen Sie uns beim Wort. Dafür stehen wir mit unserem Namen.</p>
            </div>
        </div>
        </div>
        </article>
    </Layout>
)